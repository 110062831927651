<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="vehicle" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="vehicle.id">
        <getecma-header size="lg">Editar Veículo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-vehicle-edit-information @save="save" />
      </div>
      <div v-if="!vehicle_id">
        <getecma-header size="lg">Adicionar Veículo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-vehicle-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenVehicle, goToVehicleForbiddenPage } from '@/modules/vehicle/vehicle.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getVehicleById, updateVehicleInformation, createNewVehicle } from '@/modules/vehicle/vehicle.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { VEHICLES_URL } from '@/modules/vehicle/vehicle.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaVehicleEditInformation from '@/modules/vehicle/components/VehicleEditComponent.vue';
import GetecmaVehicleCreateInformation from '@/modules/vehicle/components/VehicleCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaVehicleEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaVehicleEditInformation,
    GetecmaVehicleCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const vehicleEditVm = {};
    Object.defineProperty(vehicleEditVm, 'vehicle', {
      get: () => this.vehicle,
    });
    return { vehicleEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Veículos', path: VEHICLES_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      vehicle: null,
      createdVehicle: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    vehicle_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.vehicle_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToVehicleForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToVehicleForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.vehicle_id) {
        getVehicleById(this.vehicle_id)
          .then(data => {
            this.vehicle = data;
          })
          .catch(() => toastError('Erro ao obter veículo por ID'));
      } else {
        this.vehicle = {
          license_plate: '',
        };
      }
    },
    save() {
      if (this.vehicle_id) {
        this.fullscreenLoading = true;
        updateVehicleInformation(this.vehicle)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Veículo salvo!');
            goToOpenVehicle(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do veículo');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewVehicle(this.vehicle);
      }
    },
    onCreateNewVehicle(vehicle) {
      createNewVehicle(vehicle)
        .then(data => {
          goToOpenVehicle(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o veículo');
        });
    },
  },
};
</script>
