<template>
  <div>
    <getecma-form v-if="vehicle" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="vehicle.license_plate"
            label="Placa*"
            name="placa do carro"
            type="text"
            rules="required"
            placeholder="ex.: LGE4X22" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <color-picker v-model="color" />
          <getecma-header size="sm" class="mt--xl mb--md">Cor Selecionada: </getecma-header>
          <svg height="32" width="32">
            <circle cx="16" cy="16" r="15" :fill="color" />
          </svg>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Modelo*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            v-bind="$attrs"
            name="modelo"
            rules="required"
            placeholder="Selecione o modelo"
            :options="models"
            @on-change="onModelChange">
          </getecma-select>
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import ColorPicker from 'vue-color-picker-wheel';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchModels } from '@/modules/model/model.service';

export default {
  name: 'GetecmaVehicleCreateInformation',
  components: {
    ColorPicker,
  },
  inject: ['vehicleEditVm'],
  data() {
    return {
      vehicle: this.vehicleEditVm.vehicle,
      performer: getters.getUser(),
      models: [],
      color: '#ffffff',
    };
  },
  mounted() {
    this.fetchModels();
  },
  methods: {
    goHistoryBack,
    async fetchModels() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchModels(params);
        this.models = response.rows.map(model => ({
          key: model.id,
          value: model.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os modelos de veículos:', error);
      }
    },
    create() {
      this.vehicle.color_hex = this.color;
      this.$emit('create');
    },
    onModelChange(model) {
      this.vehicle.model_id = model.key;
    },
  },
};
</script>
